import React from "react";
import ReactDOM from "react-dom/client";
import "./style.css";
import "./responsive.css"
import App from "./App";
import { setupStore } from "./redux/store";
import { Provider } from "react-redux";
import "./18n";
import "react-image-gallery/styles/css/image-gallery.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
