import React, { useCallback, useEffect, useState } from "react";
import ProductSelectedCategory from "./ProductSelectedCategory";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryAtributes,
  getCategoryList,
  getFilteredProducts,
  getProductsList,
} from "../action/MainAction";
import ProdProducer from "./ProdProducer";
// import i18n from "../18n";
import { FiMinus } from "react-icons/fi";
import Slider from "react-slider";
import { LiaTimesSolid } from "react-icons/lia";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import Loading from "./Loading";
import { loaderTwoFunc } from "../redux/MainReducer";
import axios from "axios";
import debounce from "lodash.debounce";
import { Helmet } from "react-helmet-async";
import img from "../images/leadtech_logo.png";


const SelectedCategory = () => {
  const { t, i18n } = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);

  // Max and Min Price find function
  const [allPrices, setAllPrices] = useState([]);

  // Установите минимальное и максимальное значение в соответствии с массивом цен
  const MIN = allPrices?.length > 0 ? Math.min(...allPrices) : 0;
  const MAX = allPrices?.length > 0 ? Math.max(...allPrices) : 10000;

  // Стейт для хранения текущих значений минимума и максимума
  const [values, setValues] = useState([MIN, MAX]);


  const currentLanguage = i18n.language;

  const subCatID = new URLSearchParams(window.location.search).get("id");

  const dispatch = useDispatch();

  useEffect(() => {
    
    
    dispatch(getCategoryAtributes(subCatID));

    //Поиск МИН и МАКС цены в пагинации списка товаров.
    const fetchAllPrices = async () => {
      // Проверка наличия catID и его свойства id
      let nextPage = subCatID
        ? `https://api.leadtech.az/api/products-list/?category=${subCatID}`
        : null;
      let allPricesData = [];
      while (nextPage !== null) {
        try {
          const response = await axios.get(nextPage);
          const { results, next } = response.data;

          const pricesFromResults = results.map((product) =>
            parseFloat(product.price)
          );
          allPricesData = [...allPricesData, ...pricesFromResults];

          // Проверка наличия следующей страницы
          if (next === null) {
            break; // Выход из цикла, если следующей страницы нет
          }

          nextPage = next;
        } catch (error) {
          console.error("Error fetching data:", error);
          break;
        }
      }
      setAllPrices(allPricesData);
    };
    fetchAllPrices();
  }, [dispatch, subCatID]);

  const { categorytList, categoryAtributes, filterResults, loaderTwo } =
    useSelector((state) => state.Data);
  const catID = categorytList.find((data) => data.id === Number(subCatID)); // Для отображения названия категории h3

  useEffect(() => {
    const prices = allPrices.map((product) => parseFloat(product));
    const newMin = prices.length > 0 ? Math.min(...prices) : 0;
    const newMax = prices.length > 0 ? Math.max(...prices) : 10000;
    setValues([newMin, newMax]);
  }, [allPrices]);

  // Обработчик изменения значения минимума

  const handleMinChange = (e) => {
    const newMin = parseFloat(e.target.value) || "0";
    dispatch(loaderTwoFunc());
    setValues([newMin, values[1]]);
  };

  // Обработчик изменения значения максимума
  const handleMaxChange = (e) => {
    dispatch(loaderTwoFunc());

    const newMax = parseFloat(e.target.value) || "0";

    setValues([values[0], newMax]);
  };

  // Обработчик изменения значения слайдера
  const handleSliderChange = useCallback(
    debounce((newValues) => {
      // Ваш код для отправки запроса или обновления состояния
      setValues(newValues);
      dispatch(loaderTwoFunc());
    }, 200), // 500 миллисекунд задержки
    [dispatch]
  );

  const [isShowFilter, setIsShowFilter] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);

  // CheckBox change
  const handleCheckboxChange = (item) => {
    dispatch(loaderTwoFunc());
    window.scrollTo({ top: 0, behavior: "smooth" });

    const attribute = item.attribute;
    const isSelected = selectedItems?.some(
      (selectedItem) =>
        selectedItem.attribute === attribute && selectedItem.id === item.id
    );

    if (!isSelected) {
      setSelectedItems([...selectedItems, { id: item.id, attribute }]);
    } else {
      const updatedItems = selectedItems.filter(
        (selectedItem) =>
          !(selectedItem.attribute === attribute && selectedItem.id === item.id)
      );
      setSelectedItems(updatedItems);
    }
  };

  useEffect(() => {
    const sendRequestToServer = () => {
      // Проверяем, есть ли выбранный диапазон цен
      const minPrice = values[0]; // Минимальная цена
      const maxPrice = values[1]; // Максимальная цена

      // Проверяем, есть ли выбранные чекбоксы

      const groupedItems = selectedItems.reduce((grouped, item) => {
        if (!grouped[item.attribute]) {
          grouped[item.attribute] = [];
        }
        grouped[item.attribute].push(item.id);
        return grouped;
      }, {});

      let queryParams = [];

      // Добавляем параметры фильтра по атрибутам, если они есть
      if (Object.keys(groupedItems).length > 0) {
        queryParams = Object.entries(groupedItems).map(
          ([attribute, values]) =>
            `attribute=${attribute}&value=${values.join(",")}`
        );
      }

      // Если выбран диапазон цен, добавляем параметры min_price и max_price
      if (minPrice && maxPrice) {
        queryParams.push(`min_price=${minPrice}&max_price=${maxPrice}`);
      }

      // Формируем URL с параметрами запроса
      const apiUrl = `https://api.leadtech.az/api/all-products/${subCatID}/?${queryParams.join(
        "&"
      )}`;

      // Отправляем запрос в зависимости от того, что выбрано
      if (queryParams.length > 0) {
        dispatch(getFilteredProducts(apiUrl));
      } else {
        dispatch(getProductsList(subCatID, 1)); // Если фильтры не выбраны, загружаем весь список
      }

      // Перезагружаем страницу при изменении фильтров
      setCurrentPage(1);
      // ;
    };

    sendRequestToServer();
  }, [selectedItems, values, subCatID, dispatch]);

  // Count for pagination number
  const totalPages = Math.ceil(
    selectedItems.length !== 0
      ? filterResults.count / 10
      : filterResults.count / 10
  );
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );
  const displayCount = 5;

  // Pagination handleClick

  const handleClick = (pageNumber) => {
    dispatch(loaderTwoFunc());

    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(pageNumber);
    // localStorage.setItem("testing", pageNumber)

    if (filterResults.next) {
      const updatedUrl = filterResults.next.replace(
        /(page=)\d+/,
        `$1${pageNumber}`
      );
      dispatch(getFilteredProducts(updatedUrl));
    } else {
      dispatch(getFilteredProducts(filterResults.previous));
    }
  };

  const renderPageNumbers = () => {
    const totalPages = pageNumbers.length;

    if (totalPages <= displayCount) {
      return pageNumbers.map((num, i) => (
        <li
          key={i}
          className={`page-li ${num === currentPage ? "active_number" : ""}`}
          onClick={() => handleClick(num)}
        >
          <a href="#!" className="page-link">
            {num}
          </a>
        </li>
      ));
    }

    const middleIndex = Math.ceil(displayCount / 2);
    const start =
      currentPage <= middleIndex
        ? 1
        : currentPage >= totalPages - middleIndex
        ? totalPages - displayCount + 1
        : currentPage - middleIndex + 1;

    const visiblePageNumbers = pageNumbers.slice(
      start - 1,
      start - 1 + displayCount
    );

    return (
      <>
        {start > 1 && (
          <>
            <li className="page-li" onClick={() => handleClick(1)}>
              <a href="#!" className="page-link">
                1
              </a>
            </li>
            <li>
              <span>...</span>
            </li>
          </>
        )}
        {visiblePageNumbers.map((num, i) => (
          <li
            key={i}
            className={`page-li ${num === currentPage ? "active_number" : ""}`}
            onClick={() => handleClick(num)}
          >
            <a href="#!" className="page-link">
              {num}
            </a>
          </li>
        ))}
        {start + displayCount - 1 < totalPages && (
          <>
            <li>
              <span>...</span>
            </li>
            <li className="page-li" onClick={() => handleClick(totalPages)}>
              <a href="#!" className="page-link">
                {totalPages}
              </a>
            </li>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content={`${catID?.translations?.az?.name} Bakıda almaq - LEADTECH.AZ`}
        />
        <meta
          name="description"
          content={`${catID?.translations?.az?.name} LEADTECH.AZ mağazasında satışı. Bakıda almaq - LEADTECH.AZ əlaqə nömrəsi (+994)10 260 39 02`}
        />
        <title>{`${catID?.translations?.az?.name} Bakıda satışı LeadTech-də`}</title>

        <meta property="og:locale" content="az_AZ" />
        <meta
          property="og:title"
          content={catID?.translations?.az?.name}
        />
        <meta
          property="og:description"
          content={`${catID?.translations?.az?.name} LEADTECH.AZ mağazasında satışı. Bakıda almaq - LEADTECH.AZ`}
        />
        <meta property="og:image" content={img} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta
          property="og:url"
          content={`https://leadtech.az`}
        />
        <meta property="og:type" content="product" />
      </Helmet>

      <div className="slc_ctg_container" style={{ display: "flex" }}>
        <div
          className={
            !isShowFilter ? "slc_ctg_filter" : "slc_ctg_filter open_filter"
          }
        >
          <div className="slc_ctg_box">
            <div className="slc_prod_name">
              <h3>
                {catID?.translations[currentLanguage]?.name.toLocaleUpperCase()}
              </h3>
              <span>({filterResults.count})</span>
              <div
                className="close_filter"
                onClick={() => setIsShowFilter(!isShowFilter)}
              >
                <LiaTimesSolid />
              </div>
            </div>
            <div className="slc_filter">
              <p>Filtr</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
              >
                <path
                  d="M7.6709 9.5C9.20709 9.5 10.4943 10.5658 10.8335 11.9983L20.1709 12C20.5851 12 20.9209 12.3358 20.9209 12.75C20.9209 13.1297 20.6387 13.4435 20.2727 13.4932L20.1709 13.5L10.8338 13.5007C10.4949 14.9337 9.20745 16 7.6709 16C6.13435 16 4.84684 14.9337 4.50802 13.5007L1.6709 13.5C1.25668 13.5 0.920898 13.1642 0.920898 12.75C0.920898 12.3703 1.20305 12.0565 1.56913 12.0068L1.6709 12L4.50802 11.9993C4.84684 10.5663 6.13435 9.5 7.6709 9.5ZM7.6709 11C6.90676 11 6.25701 11.4898 6.01843 12.1725L5.99786 12.2352L5.95937 12.3834C5.93416 12.5016 5.9209 12.6242 5.9209 12.75C5.9209 12.9048 5.94101 13.055 5.97875 13.1979L6.01856 13.3279L6.04425 13.3966C6.30145 14.0431 6.93281 14.5 7.6709 14.5C8.43466 14.5 9.08414 14.0107 9.32302 13.3285L9.36308 13.1978L9.346 13.2581C9.39471 13.0973 9.4209 12.9267 9.4209 12.75C9.4209 12.6452 9.41168 12.5425 9.39402 12.4428L9.36399 12.3057L9.34398 12.2353L9.29715 12.1024C9.03971 11.4565 8.40861 11 7.6709 11ZM14.1709 0C15.7075 0 16.995 1.06632 17.3338 2.49934L20.1709 2.5C20.5851 2.5 20.9209 2.83579 20.9209 3.25C20.9209 3.6297 20.6387 3.94349 20.2727 3.99315L20.1709 4L17.3338 4.00066C16.995 5.43368 15.7075 6.5 14.1709 6.5C12.6343 6.5 11.3468 5.43368 11.008 4.00066L1.6709 4C1.25668 4 0.920898 3.66421 0.920898 3.25C0.920898 2.8703 1.20305 2.55651 1.56913 2.50685L1.6709 2.5L11.0083 2.49833C11.3474 1.06582 12.6347 0 14.1709 0ZM14.1709 1.5C13.4068 1.5 12.757 1.98976 12.5184 2.6725L12.4979 2.73515L12.4594 2.88337C12.4342 3.0016 12.4209 3.12425 12.4209 3.25C12.4209 3.40483 12.441 3.55497 12.4788 3.69794L12.5186 3.82787L12.5443 3.89664C12.8014 4.54307 13.4328 5 14.1709 5C14.9347 5 15.5841 4.51073 15.823 3.82852L15.8631 3.69781L15.846 3.75808C15.8947 3.59729 15.9209 3.4267 15.9209 3.25C15.9209 3.14518 15.9117 3.04251 15.894 2.94275L15.864 2.80565L15.844 2.73529L15.7972 2.60236C15.5397 1.95647 14.9086 1.5 14.1709 1.5Z"
                  fill="#777777"
                />
              </svg>
            </div>
            <>
              <div className="slc_price_context_name">
                <h4>{t("price")}</h4>

                <FiMinus size={16} />
              </div>

              <div className="slc_price_context">
                <div className="price_input">
                  <div className="price_field">
                    <input
                      type="number"
                      className="input_min"
                      value={values[0]}
                      onChange={handleMinChange}
                      placeholder={values[0]}
                    />
                    <span>₼</span>
                  </div>
                  <div className="separator">
                    <FiMinus size={20} />
                  </div>
                  <div className="price_field">
                    <input
                      type="number"
                      className="input_max"
                      value={values[1]}
                      placeholder={values[1]}
                      onChange={handleMaxChange}
                    />
                    <span>₼</span>
                  </div>
                </div>
                <div className="sliderOfFilter">
                  <Slider
                    className="slider"
                    onChange={handleSliderChange}
                    value={values}
                    min={MIN}
                    max={MAX}
                  />
                </div>
              </div>

              {categoryAtributes.map((data, i) => (
                <ProdProducer
                  key={i}
                  data={data}
                  handleCheckboxChange={handleCheckboxChange}
                />
              ))}
            </>
            <button
              className="filter_btn"
              onClick={() => setIsShowFilter(!isShowFilter)}
            >
              {t("showResult")}
            </button>
          </div>
        </div>
        <div className="slc_respon_container">
          <div className="slc_filter_resp_container">
            <div
              className="slc_filter_responsive"
              onClick={() => setIsShowFilter(!isShowFilter)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
              >
                <path
                  d="M7.6709 9.5C9.20709 9.5 10.4943 10.5658 10.8335 11.9983L20.1709 12C20.5851 12 20.9209 12.3358 20.9209 12.75C20.9209 13.1297 20.6387 13.4435 20.2727 13.4932L20.1709 13.5L10.8338 13.5007C10.4949 14.9337 9.20745 16 7.6709 16C6.13435 16 4.84684 14.9337 4.50802 13.5007L1.6709 13.5C1.25668 13.5 0.920898 13.1642 0.920898 12.75C0.920898 12.3703 1.20305 12.0565 1.56913 12.0068L1.6709 12L4.50802 11.9993C4.84684 10.5663 6.13435 9.5 7.6709 9.5ZM7.6709 11C6.90676 11 6.25701 11.4898 6.01843 12.1725L5.99786 12.2352L5.95937 12.3834C5.93416 12.5016 5.9209 12.6242 5.9209 12.75C5.9209 12.9048 5.94101 13.055 5.97875 13.1979L6.01856 13.3279L6.04425 13.3966C6.30145 14.0431 6.93281 14.5 7.6709 14.5C8.43466 14.5 9.08414 14.0107 9.32302 13.3285L9.36308 13.1978L9.346 13.2581C9.39471 13.0973 9.4209 12.9267 9.4209 12.75C9.4209 12.6452 9.41168 12.5425 9.39402 12.4428L9.36399 12.3057L9.34398 12.2353L9.29715 12.1024C9.03971 11.4565 8.40861 11 7.6709 11ZM14.1709 0C15.7075 0 16.995 1.06632 17.3338 2.49934L20.1709 2.5C20.5851 2.5 20.9209 2.83579 20.9209 3.25C20.9209 3.6297 20.6387 3.94349 20.2727 3.99315L20.1709 4L17.3338 4.00066C16.995 5.43368 15.7075 6.5 14.1709 6.5C12.6343 6.5 11.3468 5.43368 11.008 4.00066L1.6709 4C1.25668 4 0.920898 3.66421 0.920898 3.25C0.920898 2.8703 1.20305 2.55651 1.56913 2.50685L1.6709 2.5L11.0083 2.49833C11.3474 1.06582 12.6347 0 14.1709 0ZM14.1709 1.5C13.4068 1.5 12.757 1.98976 12.5184 2.6725L12.4979 2.73515L12.4594 2.88337C12.4342 3.0016 12.4209 3.12425 12.4209 3.25C12.4209 3.40483 12.441 3.55497 12.4788 3.69794L12.5186 3.82787L12.5443 3.89664C12.8014 4.54307 13.4328 5 14.1709 5C14.9347 5 15.5841 4.51073 15.823 3.82852L15.8631 3.69781L15.846 3.75808C15.8947 3.59729 15.9209 3.4267 15.9209 3.25C15.9209 3.14518 15.9117 3.04251 15.894 2.94275L15.864 2.80565L15.844 2.73529L15.7972 2.60236C15.5397 1.95647 14.9086 1.5 14.1709 1.5Z"
                  fill="#777777"
                />
              </svg>
              <p>Filtr</p>
            </div>
          </div>
          {loaderTwo ? <Loading/>:
          
        <>
        
          <div className="diff_prod_box">

              {
                filterResults?.results?.map((data, i) => (
                  <ProductSelectedCategory key={i} data={data} />
                ))
              }

          </div>
          
          <ul className="pagination" style={{ marginTop: "10px" }}>
              <FaChevronLeft />
              {renderPageNumbers()}
              <FaChevronRight />
          </ul>
        
        </>
        }
       
        </div>
      </div>
    </>
  );
};

export default SelectedCategory;
