import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPartnersImage } from "../action/MainAction";

const Partners = () => {
  // UseEffect for partners

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPartnersImage());
  }, [dispatch]);

  const partnersImg = useSelector((state) => state.Data.partnersImg);

  return (
    <div className="partners_container">
      <div className="slide_container">
        <div className="partners_slide">
          {partnersImg.map((data, i) => (
            <div className="partners_img" key={i}>
              <img key={i} src={data.image} alt={`partners ${i}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
