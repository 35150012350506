import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { getCarouselImages } from "../action/MainAction";



// import required modules
import { Autoplay, Pagination } from "swiper/modules";

import { useDispatch, useSelector } from "react-redux";
import i18n from "../18n";

const HomeSlide = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCarouselImages());
  }, [dispatch]);

  const { carouselImages } = useSelector((state) => state.Data);

  const currentLanguage = i18n.language;

  return (
    <>
      <Swiper
        style={{ zIndex: "-999" }} // Временный дизайн после нового года удалить
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={2000}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {carouselImages.map((data, i) => (
          <SwiperSlide key={i}>
            <div className="slide_home">
              <img
                src={data[`image_${currentLanguage}`]}
                alt="leadTech slider"
                className="swipper-lazy"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default HomeSlide;
