import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import CategorySub from "../components/CategorySub";
import { Helmet, HelmetProvider } from "react-helmet-async";

import titles from "./titles/titles";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <CategorySub />
      <Footer />
    </>
  );
};

export default ContactPage;
