import React from "react";
// import i18n from "../18n";
import { useTranslation } from "react-i18next";

const Characteristic = ({ productIDList }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className="character_container">
      <h2 className="charac_head">{t("atributies")}</h2>
      <div className="charac_context">
        <ul className="charact">
          {productIDList?.attributes?.map((data, i) => (
            <li key={i} className="charac_list">
              <p>
                {data?.attribute?.translations[currentLanguage]?.name ||
                  data?.attribute?.translations?.az?.name}
              </p>

              <p>
                {data?.value?.translations[currentLanguage]?.value ||
                  data?.value?.translations?.az?.value}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Characteristic;
