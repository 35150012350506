import React from "react";
import Navbar from "../components/Navbar";
import SinglePage from "../components/SinglePage";
import Footer from "../components/Footer";
import Hero from "../components/Hero";

import titles from "./titles/titles";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SingleProdPage = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <SinglePage />
      <Footer />
    </>
  );
};

export default SingleProdPage;
