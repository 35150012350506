import React from "react";
import SearchResultMap from "./SearchResultMap";
import { useTranslation } from "react-i18next";

const SearchResult = ({ resultsTitle }) => {
  const { t } = useTranslation();
  return (
    <div className="search_list_modal">
      {resultsTitle.length > 0 ? (
        resultsTitle.map((data, i) => {
          return <SearchResultMap data={data} key={i} />;
        })
      ) : (
        <p className="if_no_result_search">{t("searchErr")}</p>
      )}
    </div>
  );
};

export default SearchResult;
