import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCategoryList } from "../action/MainAction";
import i18n from "../18n";
import Loading from "./Loading";
import { loaderTwoFunc } from "../redux/MainReducer";
import img from "../images/leadtech_logo.png"
import { Helmet } from "react-helmet-async";
import titles from "../pages/titles/titles";

const CategorySub = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryList());
  }, [dispatch]);

  const categorytList = useSelector((state) => state.Data.categorytList);
  const loaderCatSub = useSelector((state) => state.Data.loaderCatSub);

  const subCatID = new URLSearchParams(window.location.search).get("id");

  const filterData =
    categorytList.length !== 0
      ? categorytList.filter((x) => x?.parent?.id === Number(subCatID))
      : [];

  const filterData2 =
    categorytList.length !== 0
      ? categorytList.filter((x) =>
          filterData.map((item) => item.id).includes(x?.parent?.id)
        )
      : [];

  const currentLanguage = i18n.language;

  const openCategoryList = (x, e) => {
    const formattedCategory = x.translations.az.name.toLowerCase();
    const replacedCategory = formattedCategory.replace(
      /[əğöüşı]/g,
      (char) => ({ ə: "e", ğ: "g", ö: "o", ü: "u", ş: "sh", ı: "i" }[char])
    );
    const sanitizedCategory = replacedCategory
      .replace(/[^\w-]/g, "-")
      .replace(/--+/g, "-");

    const url = categorytList.some((data) => data?.parent?.id === x.id)
      ? ""
      : `/category/${sanitizedCategory}?id=${x.id}`;

    if (url && e) {
      e.preventDefault();
      window.location.href = url;
    }
  };

  const openCategoryList2 = (x) => {
    dispatch(loaderTwoFunc());
    const formattedCategory = x.translations.az.name.toLowerCase();
    const replacedCategory = formattedCategory.replace(
      /[əğöüşı]/g,
      (char) => ({ ə: "e", ğ: "g", ö: "o", ü: "u", ş: "sh", ı: "i" }[char])
    );
    const sanitizedCategory = replacedCategory
      .replace(/[^\w-]/g, "-")
      .replace(/--+/g, "-");

    const url = categorytList.some((data) => data?.parent?.id === x.id)
      ? ""
      : `/category/${sanitizedCategory}?id=${x.id}`;
     window.location.href = url;
  };

  
  return (

    <>
      <Helmet>
        <meta
          name="keywords"
          content="leadtech.az - Kateqoriya üzrə mobil telefonlar, planşetlər, məişət texnikası və elektronika İnternet mağaza Bakı."
        />
        <meta
          name="description"
          content="leadtech.az - Kateqoriya üzrə mobil telefonlar, planşetlər, məişət texnikası və elektronika İnternet mağaza Bakı - leadtech.az. 
          Azərbaycan üzrə məişət texnika."
        />
        <title>{`leadtech.az: ${filterData && filterData[0]?.parent?.translations[currentLanguage]?.name}`}</title>
        <meta property="og:locale" content="az_AZ" />
        <meta
          property="og:title"
          content="LEADTECH.AZ: Kateqoriya üzrə məişət texnikası və elektronika İnternet mağaza Bakı."
        />
        <meta
          property="og:description"
          content="LEADTECH.AZ: Kateqoriya mobil telefonlar, planşetlər, məişət texnikası və elektronika İnternet mağaza Bakı.
           Azərbaycan üzrə məişət texnika - LEADTECH.AZ"
        />
        <meta property="og:image" content={img} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:url" content="https://leadtech.az" />
        <meta property="og:type" content="article" />
      </Helmet>
      
      <div className="products_container">
        <h1>{filterData && filterData[0]?.parent?.translations[currentLanguage]?.name}</h1>
        <div className="products_box">
          {loaderCatSub ? (
            <Loading />
          ) : (
            filterData.length !== 0 &&
            filterData.map((data, i) => (
              <div key={data.id} className="products_card_category">
                <div className="product_img">
                  <img src={data.image} alt="" style={{ cursor: "pointer" }} />
                </div>

                <h3 onClick={(event) => openCategoryList(data, event)}>
                  {data?.translations[currentLanguage]?.name ||
                    data?.translations?.az?.name}
                </h3>

                {filterData2.length !== 0 && (
                  <div className="sub_category_css">
                    {filterData2.map((data2) => {
                      if (data2.parent.id === data.id) {
                        return (
                          <ul key={data2.id} style={{ marginLeft: "15px" }}>
                            <li
                              onClick={() => openCategoryList2(data2)}
                              style={{ marginTop: "10px" }}
                            >
                              {data2?.translations[currentLanguage]?.name ||
                                data2?.translations?.az?.name}
                            </li>
                          </ul>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    
    </>
    
  );
};

export default CategorySub;
