import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Category from "../components/Category";
import Title from "../components/Title";
import Partners from "../components/Partners";
import Footer from "../components/Footer";
import HomeSlide from "../components/HomeSlide";

import titles from "./titles/titles";
import { Helmet } from "react-helmet-async";
import img from "../images/leadtech_logo.png";

const Home = () => {
  return (
    <>
      {/* <HomeModal/> // Этот компонент отвечает за сплывающее окно */}

      <Helmet>
        <meta
          name="keywords"
          content="leadtech.az - mobil telefonlar, planşetlər, məişət texnikası və elektronika İnternet mağaza Bakı. 
            Digər avadanlıqlar. Azərbaycan üzrə məişət texnika."
        />
        <meta
          name="description"
          content="leadtech.az - mobil telefonlar, planşetlər, məişət texnikası və elektronika İnternet mağaza Bakı. 
            Digər avadanlıqlar. Azərbaycan üzrə məişət texnika."
        />
        <title>{titles.home}</title>
        <meta property="og:locale" content="az_AZ" />
        <meta
          property="og:title"
          content="LEADTECH.AZ: Məişət texnikası və elektronika İnternet mağaza Bakı. 
            Digər avadanlıqlar. Azərbaycan üzrə məişət texnika"
        />
        <meta
          property="og:description"
          content="LEADTECH.AZ: mobil telefonlar, planşetlər, məişət texnikası və elektronika İnternet mağaza Bakı. 
            Digər avadanlıqlar. Azərbaycan üzrə məişət texnika"
        />
        <meta property="og:image" content={img} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:url" content="https://leadtech.az" />
        <meta property="og:type" content="article" />
      </Helmet>

      <Navbar />
      <Hero />
      <HomeSlide />
      <Category />
      <Title />
      <Partners />
      <Footer />
    </>
  );
};

export default Home;
