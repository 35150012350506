import React, { useEffect, useState } from "react";

const SnowFall = () => {
  const [snowflakes, setSnowflakes] = useState([]);
  const marginbottom = document.documentElement.clientHeight + 50;
  const marginright = document.body.clientWidth - 15;
  const snowmax = 20;
  const snowcolor = ["#b9dff5", "#7fc7ff", "#7fb1ff", "#7fc7ff", "#b9dff5"];
  const snowtype = ["Times"];
  const snowletter = "\u2744"; // Unicode snowflake character
  const sinkspeed = 0.4;
  const snowmaxsize = 30;
  const snowminsize = 10;
  //   const snowingzone = 1;

  const randommaker = (range) => Math.floor(range * Math.random());

  const initializeSnowflakes = () => {
    const snowflakesArray = [];
    const snowsizerange = snowmaxsize - snowminsize;

    for (let i = 0; i <= snowmax; i++) {
      snowflakesArray.push({
        id: i,
        posx: randommaker(marginright - snowmaxsize),
        posy: randommaker(2 * marginbottom - marginbottom - 2 * snowmaxsize),
        size: randommaker(snowsizerange) + snowminsize,
        fontFamily: snowtype[randommaker(snowtype.length)],
        color: snowcolor[randommaker(snowcolor.length)],
        zIndex: 1000,
        sink: (sinkspeed * (randommaker(snowsizerange) + snowminsize)) / 5,
      });
    }

    setSnowflakes(snowflakesArray);
  };

  useEffect(() => {
    initializeSnowflakes();
  }, []); // Run only once on mount

  const moveSnow = () => {
    setSnowflakes((prevSnowflakes) =>
      prevSnowflakes.map((snowflake) => {
        const newPosY = snowflake.posy + snowflake.sink;

        if (newPosY >= marginbottom - 2 * snowflake.size) {
          snowflake.posx = randommaker(marginright - snowflake.size);
          snowflake.posy = 0;
        } else {
          snowflake.posy = newPosY;
        }

        return snowflake;
      })
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      moveSnow();
    }, 50);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <>
      {snowflakes.map((snowflake) => (
        <span
          key={snowflake.id}
          style={{
            position: "fixed",
            top: `${snowflake.posy}px`,
            left: `${snowflake.posx}px`,
            fontFamily: snowflake.fontFamily,
            fontSize: `${snowflake.size}px`,
            color: snowflake.color,
            zIndex: snowflake.zIndex,
            pointerEvents: "none",
            userSelect: "none",
          }}
        >
          {snowletter}
        </span>
      ))}
    </>
  );
};

export default SnowFall;
