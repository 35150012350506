import React, { useState, useEffect, useRef } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import i18n from "../18n";

const ProdProducer = ({ data, handleCheckboxChange }) => {
  const [isActive, setIsActive] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const currentLanguage = i18n.language;
  const containerRef = useRef(null);

  const accorOpen = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (containerRef.current) {
      setContentHeight(containerRef.current.scrollHeight);
    }
  }, [isActive]);

  return (
    <div className="prod_producer">
      <div
        className="slc_price_context_name"
        onClick={() => accorOpen(data.translations.az.name)}
      >
        <h4>
          {data?.translations[currentLanguage]?.name ||
            data?.translations?.az?.name}
        </h4>
        {isActive ? <FiMinus size={16} /> : <FiPlus size={16} />}
      </div>
      <div
        ref={containerRef}
        className={`phoneName_container ${isActive ? "active" : ""}`}
        style={{ maxHeight: isActive ? `${contentHeight}px` : "0" }}
      >
        {data.values.map((item, index) => (
     

          <div className="phoneName_boxes" key={index}>
            <div className="phoneName_box">
              <input
                className="custom-checkbox"
                id={item.translations[currentLanguage]?.value}
                type="checkbox"
                onChange={() => handleCheckboxChange(item)}
                value={item.translations[currentLanguage]?.value}
              />
              <label htmlFor={item.translations[currentLanguage]?.value}>
                {item?.translations[currentLanguage]?.value ||
                  item?.translations?.az?.value}
              </label>
            </div>
          </div>

    

        ))}
      </div>
    </div>
  );
};

export default ProdProducer;
