import React from "react";
import i18n from "../18n";

// import { useNavigate } from "react-router-dom";

const SubSubMenuItems = ({
  selectCategory,
  setIsOpen,
  isOpen,
  categorytList,
}) => {
  // const navigate = useNavigate();

  const currentLanguage = i18n.language;

  const openSubCategory = (x) => {
    
    const formattedCategory = x.translations.az.name.toLowerCase();

    const replacedCategory = formattedCategory.replace(
      /[əğöüş]/g,
      (char) => ({ ə: "e", ğ: "g", ö: "o", ü: "u", ş: "sh" }[char])
    );
    const sanitizedCategory = replacedCategory
      .replace(/[^\w-]/g, "-")
      .replace(/--+/g, "-");

    const url = categorytList.some((data) => data?.parent?.id === x.id) 
      ? `/category/subcategory/${sanitizedCategory}?id=${x.id}`
      : `/category/${sanitizedCategory}?id=${x.id}`;

    // navigate(url);
    window.location.href = url;

    setIsOpen(!isOpen);
  };

  return (
    <div className="subsub_prod_list_container">
      {selectCategory.map((item, i) => (
        <ul key={i} className="subsub_products-list">
          <li className="subsub_item_link" style={{ border: "none" }}>
            <div className="prod_catg">
              {/* <img className="component" src={category.img} alt="comp" /> */}
              <p onClick={() => openSubCategory(item)} className="text_wrapper">
                {item?.translations[currentLanguage]?.name}
              </p>
            </div>
          </li>
        </ul>
      ))}
    </div>
  );
};

export default SubSubMenuItems;
