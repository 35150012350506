import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProductSelectedCategory = ({ data }) => {
  const { t } = useTranslation();

  const productNameForURL = data?.translations?.az?.name
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/ş/g, "sh")
    .replace(/ə/g, "e")
    .replace(/ü/g, "u")
    .replace(/ı/g, "i")
    .replace(/ğ/g, "g")
    .replace(/ö/g, "o")
    .replace(/ç/g, "ch");

  return (
    <>
      <Link
        to={`/product/${encodeURIComponent(productNameForURL)}/${data?.id}`}
      >
        <div className="diff_prod_card">
          <div className="diff_prod_img">
            <LazyLoadImage loading="lazy" src={data?.image?.image} alt="" effect="blur"/>
          </div>
          <p className="diff_prod_name">{data?.translations?.az?.name}</p>
          <div className="diff_prod_price_cont">
            {data.final_price ? (
              <div>
                <del className="diff_prod_price">
                  <p>
                    {data.price} <span>₼</span>
                  </p>
                </del>
                <p className="diff_prod_sale1">
                  {data.final_price} <span>₼</span>
                </p>
              </div>
            ) : (
              <p className="diff_prod_price1">
                {data.price} <span>₼</span>
              </p>
            )}
            {data.in_stock === "Var" ? (
              <p
                className="stock_status"
                style={{
                  backgroundColor: "rgba(37, 174, 96, 0.1)",
                  color: "rgb(37, 174, 96)",
                  display: "inline-block",
                }}
              >
                {t("stockStatus1")}
              </p>
            ) : data.in_stock === "Yoxdur" ? (
              <p
                className="stock_status"
                style={{
                  backgroundColor: "rgb(218 40 40 / 10%)",
                  color: "rgb(0 187 202)",
                  display: "inline-block",
                }}
              >
                {t("stockStatus2")}
              </p>
            ) : (
              <p
                className="stock_status"
                style={{
                  backgroundColor: "rgba(37, 174, 96, 0.1)",
                  color: "rgb(37, 174, 96)",
                  display: "inline-block",
                }}
              >
                {t("stockStatus3")}
              </p>
            )}
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProductSelectedCategory;
