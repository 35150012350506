import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import SubMenuItems from "./SubMenuItems";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../action/MainAction";
import { useTranslation } from "react-i18next";
import { GoArrowLeft } from "react-icons/go";
import { LiaTimesSolid } from "react-icons/lia";

const DropDown = () => {
  let isHomePage = useLocation().pathname === "/";
  // const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(isHomePage ? false : true);
  const [menuActive, setMenuActive] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [imageCat, setImageCat] = useState();

  function onMouseLeave() {
    setSelectedCategory(null);
  }

  function onClick() {
    setSelectedCategory(null);
    setIsOpen(!isOpen);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryList());
  }, [dispatch]);

  const categorytList = useSelector((state) => state.Data.categorytList);

  const selectCategoryFunc = (data, image) => {
    setImageCat(image);
    let filterData = categorytList.filter((x) => x?.parent?.id === data.id);

    setSelectedCategory(filterData);
  };

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const openSubCategory = (x) => {
    const formattedCategory = x.translations.az.name.toLowerCase();
    const replacedCategory = formattedCategory.replace(
      /[əğöüşı]/g,
      (char) => ({ ə: "e", ğ: "g", ö: "o", ü: "u", ş: "sh", ı: "i" }[char])
    );
    const sanitizedCategory = replacedCategory
      .replace(/[^\w-]/g, "-")
      .replace(/--+/g, "-");

    const url = categorytList.some((data) => data?.parent?.id === x.id)
      ? `/category/subcategory/${sanitizedCategory}?id=${x.id}`
      : `/category/${sanitizedCategory}?id=${x.id}`;

    window.location.href = url;
    setIsOpen(!isOpen);
  };

  const setMenuActiveFunc = () => {
    setMenuActive(!menuActive);
    setIsOpen(false);
  };

  return (
    <>
      <div className="resp_catalog" onClick={setMenuActiveFunc}>
        <div className="resp_catalog_style">
          <div>
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.59082 0V4.90909H5.49991V0H0.59082ZM2.22718 1.63636H3.86355V3.27273H2.22718V1.63636ZM7.13627 1.63636V3.27273H13.2726H19.409V1.63636H7.13627ZM0.59082 6.54545V11.4545H5.49991V6.54545H0.59082ZM2.22718 8.18182H3.86355V9.81818H2.22718V8.18182ZM7.13627 8.18182V9.81818H19.409V8.18182H7.13627ZM0.59082 13.0909V18H5.49991V13.0909H0.59082ZM2.22718 14.7273H3.86355V16.3636H2.22718V14.7273ZM7.13627 14.7273V16.3636H19.409V14.7273H7.13627Z"
                fill="white"
              />
            </svg>
            <div className="menu_text">{t("catalogName")}</div>
          </div>
        </div>

        <div className="dropdown_wrapper2">
          <ul
            className={
              !menuActive ? "catg_list2" : "catg_list2 open_catg_list2"
            }
          >
            <div className="catalog_navbar_container">
              <div className="catalog_navbar">
                <div className="arrow_back_to_head">
                  <GoArrowLeft />
                </div>
                <div className="close_to_head">
                  <LiaTimesSolid />
                </div>
              </div>
            </div>
            {categorytList.map((category) =>
              category?.parent === null ? (
                <li className="item_link" key={category.id}>
                  <div className="prod_catg">
                    <img
                      className="component"
                      src={category.svg_image}
                      alt="comp"
                      loading="lazy"
                    />
                    <p
                      className="text_wrapper"
                      onClick={() => openSubCategory(category)}
                    >
                      {category?.translations[currentLanguage]?.name ||
                        category?.translations?.az?.name}
                    </p>
                  </div>

                  {categorytList.find((x) => x?.parent?.id === category.id) ? (
                    <IoIosArrowForward className="icon-outline" />
                  ) : (
                    ""
                  )}
                </li>
              ) : null
            )}
          </ul>
        </div>
      </div>

      <div className="catg_menu" onMouseLeave={onMouseLeave}>
        <div className={!isOpen ? "head_of_menu" : "head_of_menu_black"}>
          <div onClick={onClick}>
            {!isOpen ? (
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="la:list-ul">
                  <path
                    id="Vector"
                    d="M2.59082 3.07324V7.98233H7.49991V3.07324H2.59082ZM4.22718 4.70961H5.86355V6.34597H4.22718V4.70961ZM9.13627 4.70961V6.34597H15.2726H21.409V4.70961H9.13627ZM2.59082 9.6187V14.5278H7.49991V9.6187H2.59082ZM4.22718 11.2551H5.86355V12.8914H4.22718V11.2551ZM9.13627 11.2551V12.8914H21.409V11.2551H9.13627ZM2.59082 16.1642V21.0732H7.49991V16.1642H2.59082ZM4.22718 17.8005H5.86355V19.4369H4.22718V17.8005ZM9.13627 17.8005V19.4369H21.409V17.8005H9.13627Z"
                    fill="#333E48"
                  />
                </g>
              </svg>
            ) : (
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.59082 0V4.90909H5.49991V0H0.59082ZM2.22718 1.63636H3.86355V3.27273H2.22718V1.63636ZM7.13627 1.63636V3.27273H13.2726H19.409V1.63636H7.13627ZM0.59082 6.54545V11.4545H5.49991V6.54545H0.59082ZM2.22718 8.18182H3.86355V9.81818H2.22718V8.18182ZM7.13627 8.18182V9.81818H19.409V8.18182H7.13627ZM0.59082 13.0909V18H5.49991V13.0909H0.59082ZM2.22718 14.7273H3.86355V16.3636H2.22718V14.7273ZM7.13627 14.7273V16.3636H19.409V14.7273H7.13627Z"
                  fill="white"
                />
              </svg>
            )}
            <div className="menu_text">{t("catalogName")}</div>
          </div>
        </div>

        {!isOpen && (
          <div className="dropdown_wrapper">
            <ul className="catg_list">
              {categorytList.map((category) =>
                category?.parent === null ? (
                  <li
                    className="item_link"
                    key={category.id}
                    onMouseOver={() =>
                      selectCategoryFunc(category, category.image)
                    }
                  >
                    <div className="prod_catg">
                      <img
                        className="component"
                        src={category.svg_image}
                        alt="comp"
                        loading="lazy"
                      />
                      <p
                        className="text_wrapper"
                        onClick={() => openSubCategory(category)}
                      >
                        {category?.translations[currentLanguage]?.name ||
                          category?.translations?.az?.name}
                      </p>
                    </div>

                    {categorytList.find(
                      (x) => x?.parent?.id === category.id
                    ) ? (
                      <IoIosArrowForward className="icon-outline" />
                    ) : (
                      ""
                    )}
                  </li>
                ) : null
              )}
            </ul>
            {selectedCategory && (
              <div className="submenu">
                <SubMenuItems
                  data={selectedCategory}
                  imageCat={imageCat}
                  categorytList={categorytList}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DropDown;
