import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCardsImage } from "../action/MainAction";
import i18n from "../18n";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { Link } from "react-router-dom";
// ..
AOS.init({ delay: 1000 });

const Category = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCardsImage());
  }, [dispatch]);

  const { cardsImage } = useSelector((state) => state.Data);
  
  const currentLanguage = i18n.language;
  return (
    <div className="three_container">
      {cardsImage?.map((data, i) => (
        <div data-aos="zoom-in" key={i}>
          <Link to={data?.url} target="_blank">
          <div key={i} className="three_card">
            <div className="three_img"> 
              <img src={data?.image} alt="camera leadtech" />
            </div>
            <div className="three_text">
              <p className="three_text_head">
                {data?.translations[currentLanguage]?.content ||
                  data?.translations?.az?.content}
              </p>
              {/* <div className="three_link">
              <p>buradan bax</p>
              <IoIosArrowForward className="icon_arrow" />
            </div> */}
            </div>
          </div>
          
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Category;
