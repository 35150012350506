import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import SelectedCategory from "../components/SelectedCategory";
import Footer from "../components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";

import titles from "./titles/titles";

const CategoryPage = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <SelectedCategory />
      <Footer />
    </>
  );
};

export default CategoryPage; 
