import React, { useEffect, useState } from "react";
import Characteristic from "./Characteristic";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInstCards, getProductIDList } from "../action/MainAction";
import { useTranslation } from "react-i18next";
import i18n from "../18n";
import Loading from "./Loading";
import { isLoaderFuncStart, loaderTwoFunc } from "../redux/MainReducer";

import ImageGallery from "react-image-gallery";
import axios from "axios";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { Helmet } from "react-helmet-async";

// ..
AOS.init({ delay: 400 });

const SinglePage = () => {
  const { productName, id } = useParams();
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInstCards());
  }, [dispatch]);

  const instCards = useSelector((state) => state.Data.instCards);

  useEffect(
    () => {
      window.scrollTo(0, 0);
      dispatch(loaderTwoFunc());
    },
    [dispatch],
    productName,
    id
  );

  const productIDList = useSelector((state) => state.Data.productIDList);
  const loaderTwo = useSelector((state) => state.Data.loaderTwo);

  useEffect(() => {
    dispatch(isLoaderFuncStart())
    if (id) {
      dispatch(getProductIDList(id));
    } else {
    }
  }, [dispatch, id]);

  const { t } = useTranslation();

  // useEffect for single page photos

  const [imagesFromApi, setImagesFromApi] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.leadtech.az/api/product/${id}/`)
      .then((response) => {
        const data = response.data;
        // console.log(data);

        // Проверяем, что images_by_color не пустой массив и содержит объект с images
        if (data.images_by_color && data.images_by_color.length > 0) {
          const imagesData = data.images_by_color[0].images.map((imageUrl) => ({
            original: imageUrl,
            thumbnail: imageUrl, // Здесь можно указать другой URL для миниатюр, если нужно
          }));

          setImagesFromApi(imagesData);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [id]);

  const currentLanguage = i18n.language;

  const ogImage =
    productIDList &&
    productIDList?.images_by_color &&
    productIDList?.images_by_color[0]?.images[0];

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content={`${productIDList?.category?.translations?.az?.name} ${productIDList?.translations?.az?.name} Bakıda almaq - LEADTECH.AZ`}
        />
        <meta
          name="description"
          content={`${productIDList?.category?.translations?.az?.name} 
          ${productIDList?.translations?.az?.name}. Qiyməti: ${productIDList.price} 
          AZN LEADTECH.AZ mağazasında satışı. Bakıda almaq - LEADTECH.AZ əlaqə nömrəsi (+994)10 260 39 02`}
        />
        <title>{`${productIDList?.translations?.az?.name} Bakıda satışı LeadTech-də ${productIDList.price} AZN LEADTECH.AZ`}</title>
       
        <meta property="og:locale" content="az_AZ" />
        <meta
          property="og:title"
          content={productIDList?.translations?.az?.name}
        />
        <meta
          property="og:description"
          content={productIDList?.category?.translations?.az?.name}
        />
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta
          property="og:url"
          content={`https://leadtech.az/product/${productName}/${id}`}
        />
        <meta property="og:type" content="product" />
      </Helmet>

      <div className="single_container">
        <h2 className="single_head">
          {productIDList?.translations?.[currentLanguage]?.name ||
            productIDList?.translations?.az?.name}
        </h2>

        <>
          {loaderTwo ? (
            <Loading />
          ) : (
            <div className="single_box">
              <div data-aos="fade-right">
                {/* Images gallery */}
                <div className="testing123">
                  <ImageGallery
                    items={imagesFromApi}
                    showPlayButton={false}
                    showFullscreenButton={false}
                  />
                </div>
              </div>
              {/* Images items */}
              <div data-aos="fade-left">
                <div className="order_num_container">
                  <div className="order_num_container">
                    <div className="ins_card">
                      <div className="ins_card_scroller">
                        {/* <!-- Слайд 1 --> */}
                        {instCards?.map((data, i) => (
                          <div className="priceinfo_cartslider" key={i}>
                            <div className="cart_slider_logo">
                              <img
                                src={data?.image}
                                width="80"
                                height="24"
                                alt={`installmentscards ${i}`}
                              />
                            </div>
                            <div className="cart_slider_info">
                              <h4>
                                {data?.translations?.[currentLanguage]
                                  ?.bottomtxt ||
                                  data?.translations?.az?.bottomtxt}
                              </h4>
                              <p>
                                {data?.translations?.[currentLanguage]
                                  ?.headtxt || data?.translations?.az?.headtxt}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="order_num">
                    <div className="order_price">
                      {productIDList?.final_price ? (
                        <>
                          <p>
                            {productIDList?.final_price} <span>₼</span>
                          </p>
                          <p>
                            <del style={{ color: "red", fontSize: "1.6rem" }}>
                              {productIDList?.price} <span>₼</span>
                            </del>
                          </p>
                        </>
                      ) : (
                        <p>
                          {productIDList?.price} <span>₼</span>
                        </p>
                      )}
                    </div>
                    <Link to="/contact" className="call_order">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.29813 13.2031C10.4348 16.3398 13.3415 16.6833 14.1948 16.7151C15.226 16.7526 16.2783 15.9107 16.7336 15.0485C16.0075 14.1968 15.062 13.536 14.026 12.8197C13.4157 13.4299 12.6636 14.5639 11.6602 14.1576C11.0899 13.9284 9.68187 13.2798 8.45166 12.0488C7.22063 10.8186 6.5729 9.41053 6.34203 8.84111C5.93577 7.83687 7.07298 7.08227 7.684 6.47124C6.96774 5.41887 6.31837 4.4489 5.46832 3.75956C4.59379 4.2164 3.747 5.26061 3.78534 6.30563C3.81716 7.15895 4.16061 10.0656 7.29813 13.2031ZM14.1344 18.3451C12.9597 18.3018 9.63048 17.8425 6.14379 14.3558C2.65792 10.87 2.19863 7.54156 2.15458 6.366C2.08932 4.57453 3.46148 2.83445 5.04655 2.1549C5.23743 2.07248 5.44646 2.0411 5.65312 2.06384C5.85978 2.08658 6.05698 2.16266 6.22537 2.28461C7.53716 3.24153 8.44187 4.69119 9.21932 5.82677C9.38105 6.0629 9.45514 6.34814 9.42877 6.63313C9.4024 6.91812 9.27723 7.18492 9.07492 7.38737L7.96871 8.4944C8.22569 9.06137 8.74861 10.0387 9.60519 10.8953C10.4618 11.7518 11.4391 12.2748 12.0069 12.5317L13.1123 11.4255C13.3155 11.2228 13.5834 11.0978 13.8693 11.0723C14.1553 11.0468 14.4411 11.1224 14.6769 11.286C15.8354 12.0888 17.1961 12.9804 18.1881 14.2506C18.32 14.4202 18.4038 14.6221 18.431 14.8352C18.4581 15.0483 18.4275 15.2648 18.3423 15.4621C17.6595 17.0553 15.9316 18.4111 14.1344 18.3451Z"
                          fill="white"
                        />
                      </svg>
                      <p>{t("callOrder")}</p>
                    </Link>
                  </div>
                  <p className="short_value">
                    {productIDList?.translations?.[currentLanguage]
                      ?.short_description ||
                      productIDList?.translations?.az?.short_description}
                  </p>
                  <div className="stock_info">
                    {productIDList.in_stock === "Var" ? (
                      <p
                        className="stock_status"
                        style={{
                          backgroundColor: "rgba(37, 174, 96, 0.1)",
                          color: "rgb(37, 174, 96)",
                          display: "inline-block",
                        }}
                      >
                        {t("stockStatus1")}
                      </p>
                    ) : productIDList.in_stock === "Yoxdur" ? (
                      <p
                        className="stock_status"
                        style={{
                          backgroundColor: "rgb(218 40 40 / 10%)",
                          color: "rgb(0 187 202)",
                          display: "inline-block",
                        }}
                      >
                        {t("stockStatus2")}
                      </p>
                    ) : (
                      <p
                        className="stock_status"
                        style={{
                          backgroundColor: "rgba(37, 174, 96, 0.1)",
                          color: "rgb(37, 174, 96)",
                          display: "inline-block",
                        }}
                      >
                        {t("stockStatus3")}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>

        <Characteristic productIDList={productIDList} />
      </div>
    </>
  );
};

export default SinglePage;
